.home {

}

.home-bg  {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}
.home-bg > picture > source {
    width: 100%;
    height: 100%;
}
.home-bg > picture > img {
    width: 100%;
    height: 100%;
}